export enum EExploreItem {
  ORGANIZATION = 'organization',
  SITE = 'site',
  BUILDING = 'building',
  FLOOR = 'floor',
  SPACE = 'space',
}

export enum ECookieName {
  /**
   * Stores the last url before redirecting to login
   */
  BEFORE_LOGIN_URL = 'mapped:before_login_url',
  /**
   * Stores the plan the user seems to be interested in
   */
  WISHING_PLAN = 'mapped:wishing_plan',
  /**
   * Stores the Azure Subscription JSON object
   */
  AZURE_SUBSCRIPTION = 'mapped:azure_subscription',
  /**
   * Stores the accepted invite token
   */
  INVITE_TOKEN = 'mapped:invite_token',
  /**
   * Marks a session as internal (Mapped team)
   */
  INTERNAL = 'mapped:internal',
  /**
   * Clouflare Session Cookie
   */
  CF_SESSION = 'CF_AppSession',
  /**
   * Turnstile bypass token
   */
  TURNSTILE_BYPASS_SECRET = 'mapped:turnstile_bypass_secret',
}

export enum EStorageKey {
  /**
   * Stores the current auth token
   */
  AUTH_TOKEN = 'mapped:auth_token',

  /**
   * Stores the refresh token
   */
  REFRESH_TOKEN = 'mapped:refresh_token',

  LEFT_PANEL = 'mapped:left_panel',
  DATA_GRID_FILTER = 'mapped:data_grid_filter',
  LEFT_PANEL_WIDTH = 'mapped:left_panel_width',
  SHOULD_HIDE_UPGRADE_BANNER = 'mapped:should_hide_upgrade_banner',
}

export enum EFeature {
  EXPLORE = 'EXPLORE',
  CONNECTORS = 'CONNECTORS',
  GEOSPACE_VIEW = 'GEOSPACE_VIEW',
  DATAFLOW_VIEW = 'DATAFLOW_VIEW',
  GRID_VIEW = 'GRID_VIEW',
  GRIDVIEW_UNUSED_POINTS_TOGGLE = 'GRIDVIEW_UNUSED_POINTS_TOGGLE'
}

export enum EStepToken {
  IDLE = 'IDLE',
  NEW = 'NEW',
  GENERATED = 'GENERATED',
}

export interface INextAPIError {
  error?: string
  stack?: any
}
